import ImgChristianGruekedel from '../../assets/bryg/christian_ved_gruekedel_og_gaerspand_250x333.jpeg';
import ImgHumle from '../../assets/bryg/humle_250x333.jpeg';
import ImgOechlevaegt from '../../assets/bryg/oechslevaegt_35x149.jpeg';
import ImgAllanvedbrygcomputer from '../../assets/bryg/allan_ved_brygcomputer_250x333.jpeg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Sådan bryggede vi på Solholt</h1>
          <p>
            Efter vi har besluttet hvilken type øl vi vil brygge, går vi i gang med at søge i de mange opskrifter på nettet, for at finde en sammensætning der ser god ud. :-)<br />
            Det sker tit, at man ikke lige kan få den type malt man skal bruge, hvis der er specielle malttyper i opskriften. Så må man være kreativ og tilpasse opskriften efter hvad man kan få.
          </p>
          <p>
            Det samme gælder humletyper; der findes utrolig mange humlesorter, med meget forskellig bitterhed og smag.<br />
            Hvis leverandøren har udsolgt, og man har planlagt at brygge i morgen, så går man på kompromis og finder en anden humle (helst) med tilsvarende egenskaber. I øvrigt har vi selv en humleplante, som vi dog ikke kender sorten på, men som vi også eksperimenterer med.<br />
          </p>
          <p>
            Gæren er lige så vigtig, så hvis ikke leverandøren har den type man skal bruge, så må man til at se hvad man kan substituere den med. Det slår som regel ikke fejl, det plejer at blive til øl – og vi har endnu ikke brygget noget der ikke smagte godt (eller i hvert fald ikke decideret dårligt :-)).<br />
          </p>
          <Col className='center'>
            <img src={ImgChristianGruekedel} width="250" height="333" alt="Christian v. gruekedel" /><br />
            Christian med en gærspand oven på gruekedelen.<br /><br />
          </Col>
          <p>
            Vi brygger i en gruekedel og brygger 20-30 liter ad gangen. Vi starter med at opvarme vandet til ca. 50 grader og tilsætter så malten. Temperaturen hæves i intervaller op til ca. 78 grader hvorefter man begynder at si malten fra (udmæskningen) hvilket tager ca. en halv time.
            <br /><br />
            Temperaturen hæves til kogepunktet og humlen tilsættes. Den koger med i 45-50 minutter hvorefter der tilsættes mere humle, der koger i 10 minutter. Herefter slukkes gruekedlen og vi køler urten ned, samtidig med at humlen sies fra.
            <br /><br />
            Vi har flere gange udmæsket med et dørslag. Denne metode er ikke optimal, da man ikke får fuldt udbytte af sukkerstofferne i malten, når der udmæskes på denne måde. Vi er i gang med at forbedre denne process – det skriver vi mere om, når vi har gjort nogle erfaringer.
            <br /><br />
            Til nedkøling af urten har vi hidtil brugt en kobberrørskøler, der sættes ned i gruekedlen og der tilsluttes kølevand til røret. Det princip virker ok, men fremover benyttes en pladekøler i stedet. Den skulle være mere effektiv – vi har dog ikke gjort egne erfaringer med den endnu, men det skriver vi også mere om, når vi får det.
          </p>

          <Col className='center'>
            <img src={ImgHumle} width="250" height="333" alt="Solholt humle - ukendt sort" /><br />
            Vores egen humleplante lige før høst i sommeren 2007.<br /><br />

            <img src={ImgOechlevaegt} width="35" height="149" alt="Oechslev&aelig;gt" /><br />
            Oechslevægten - som flere sikkert vil genkende fra 70'erne modedille: Hjemmefremstilling af vin.<br /><br />
          </Col>
          <p>
            Vi bruger ikke længere vinballoner til at gære i. De er for besværlige at gøre rene, så vi bruger i stedet fødevaregodkendte plastikspande på 30 liter. Den nedkølede urt tappes med en rengjort og desinficeret hævert over på den rengjorte og desinficerede gærspand. Vi måler vægtfylden med en Oechslevægt. Vi noterer os den målte vægtfylde, hvorefter gæren tilsættes.
            <br /><br />
            Gærspanden sættes ind i vores gærskab, der er en gammel skabsfryser udbygget med en computerstyret termostat. Computeren måler temperaturen i gærskabet og tænder og slukker for fryseren, afhængig af om der er for varmt eller koldt. Hvis temperaturen bliver for lav, tændes en varmelampe i skabet, så temperaturen kan hæves.
            <br />
            Temperatur og tidspunkter der er målt, bliver gemt i en database og vises på nettet, hvor vi også kan indstille den ønskede temperatur i skabet. :-)
            <br /><br />
            Efter en uge eller to afhængig af øltype, stikkes øllet igen om med en rengjort og desinficeret hævert over på en anden rengjort og desinficeret gærspand. I samme omgang måles vægtfylden igen, for at se hvor meget den er faldet. Gærspanden sættes til sekundær gæring i gærskabet. Gæringen går efterhånden langsommere og langsommere, og går til sidst helt i stå. Vi måler vægtfylden og giver evt. gæringen et par dage ekstra.
            <br /><br />
            Vægtfylden måler vi dels for at kunne udregne en ca. alkoholprocent, og dels for at kunne følge med i, hvor meget sukkerstof der er tilbage, idet vægtfylden falder når gæren omdanner sukkerstofferne til alkohol og CO2.
            <br /><br />
            Herefter stikkes øllet om med en rengjort og desinficeret hævert over på en rengjort og desinficeret fustage (19 liters fadøls-anker). Vi er gået væk fra at bruge flasker, da det er for besværligt at rengøre 70 flasker. :-)
            <br /><br />
            Fustagen tilsættes CO2 i passende mængde og sættes på køl til lagring. Den kan drikkes efter en uges tid, hvor CO2 mængden er tilpasset og stabil, men den er bedst efter 2-3 ugers lagring.
            <br /><br />
            Herefter er der bare at sige skål og kom i gang med at brygge. :-)
            <br /><br /><br />
          </p>
          <Col className='center'>
            <img src={ImgAllanvedbrygcomputer} width="250" height="333" alt="Allan v. brygcomputer" /><br />Allan ved det computerstyrede gærskab.
          </Col>
        </Col>
      </Row>
    </Container >
  );
}

