import ImgBryg3 from '../../assets/bryg/brew3.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>3. Bryg - Solholt Tjekkisk Pilsner</h1>
          <table>
            <tr>
              <td>
                Brygdag d. 29. juli 2006.<br />
                <i>(ingredienser købt hos Haandbryggeriet - som desværre er lukket)</i><br />
                <br />
                Malt:<br />
                Pilsner Malt, Dragsbæk Maltfabrik (3,3 EBC) - 4,7kg<br />
                Cara Munich, Dragsbæk Maltfabrik (20,1 EBC) - 0,7kg<br />
                Pale ale malt, Malterie du Château, Belgien (7,8 EBC) - 0,7kg<br />
                Hvede, Malterie du Château, Belgien (4,3 EBC) - 0,3kg<br />
                Cara 50 pilsner karamelmalt, Malterie du Château, Belgien (47 EBC) - 0,6kg<br />
                <br />
                Humle:<br />
                Hersbrucker (a 2,1%) - 120g (60 min)<br />
                Saaz ( a 2,8%) - 70g (15 min)<br />
                Saaz ( a 2,8%) - 70g (10 min)<br />
                Saaz ( a 2,8%) - 20g (tørhumle)<br />
                <br />
                Mæskeprogram:<br />
                55° i 30 min, 66° i 60 min, 75° i 15 min<br />
                <br />
                Gær: Safale S-23<br />
                1. gæring i 14 dage ved ca. 8-10°<br />
                2. gæring i 12 dage ved ca. 18°<br />
                Lagring i 12 dage ved ca. 1-2°<br />
                <br />
                OG: 1057<br />
                FG: 1018<br />
                Alkohol: ca. 4,6%<br />
                Farve: 13 EBC<br />
                Bitterhed: &gt;36 IBU (højere pga. tørhumle)<br />
                <br />
                Beskrivelse:<br />
                Mæskning forløb således: 60° i 15 min, 55° i 15 min, 70-66° i 60 min, hvoraf de første 30 min var temperaturen omkring 70°<br />
                Gæring i gærspande (levnedsmiddelgodkendt) i stedet for vinbaloner.<br />
                Starttemperaturen for gæring var 25°C, da vi ikke kunne køle urten længere ned. Temperaturen blev sænket til 8° over natten, vha. temperaturstyringen.<br />
                Den høje temperatur satte godt gang i gæringen.<br />
                Efter 14 dage tappes der til sekundær gæring. SG måles til 1036.<br />
                Lagring på Cornelius fustager for første gang. For højt CO2 tryk = ren skum ved tapning. :-(<br />
                Hjalp dog at få "luftet" ud. Øllet har en rigtig god fylde og humlebitterhed.<br />
                Vi endte med ca. 25 liter rigtig god øl. Helt klart vores favorit tjekkisk pilsner. :-)<br />

                <img src={ImgBryg3} width="670" height="300" alt="Bryg3graf" /><br />
                Temperaturgrafen - (bemærk: førsteaksen er ikke lineær).<br />
                Temperaturen er styret med to Velleman kits (k8055 usb interface board + k8067 temperatur sensor), samt relæstyring af køler og varmer. Brygget opbevares i en gammel skabsfryser, der tændes og slukkes af temperatur hardwaren. Software udviklet som en Windows .Net applikation.<br /><br />
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container >
  );
}

