import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>9. Bryg - Solholt Classic</h1>
          (egen humle - aromahumle)

          <table>
            <tr>
              <td>
                Brygdag lørdag d. 29. august 2009.<br /><br />

                Malt:<br />
                Pilsner malt, Malterie du Château, Belgien (3 EBC) - 3,7kg<br />
                Pale Ale malt, Malterie du Château, Belgien (7 EBC) - 1kg<br />
                Hvede malt, Malterie du Château, Belgien (4 EBC) - 0,7kg<br />
                Cara 50, Malterie du Château, Belgien (16 EBC) - 0,5kg<br />
                <br />
                Humle:<br />
                Pilgrim ( a 12%) - 20g (60 min)<br />
                Egen humle ( ukendt alpha) - 70g (15 min)<br />
                Egen humle ( ukendt alpha) - 80g (5 min)<br />
                Våd humle, høstet i regnvejr :-(<br />
                <br />
                Gær: Saflager W-34/70<br />
                1. gæring i 13 dage ved ca. 14-15°<br />
              </td>
            </tr>
          </table>
          <br/>
          <br/>
          <br/>
        </Col>
      </Row>
    </Container >
  );
}

