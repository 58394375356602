import ImgBryggraf from '../../assets/bryg/brew7.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>7. Bryg - Solholt Hvede 2.0</h1>

          <table>
            <tr>
              <td>
                Brygget lørdag-søndag d. 9-10. februar 2008. Et længe ventet bryg - efter en lang pause.<br />
                <i>(ingredienser købt hos Haandbryggeriet - som desværre er lukket)</i><br />
                <br />
                Malt:<br />
                Hvede malt, Malterie du Château, Belgien (3 EBC) - 3,5kg (54%)<br />
                Pilsner malt, Malterie du Château, Belgien (7 EBC) - 2,5kg (38%)<br />
                Cara 120, Malterie du Château, Belgien (120 EBC) - 0,5kg (8%)<br />
                Chokolade malt (900 EBC) - 20g (0%)<br />
                <br />
                Humle:<br />
                Hersbrucker ( a 3,8%) - 45g (60 min)<br />
                Hersbrucker ( a 3,8%) - 10g (10 min)<br />
                Hersbrucker ( a 3,8%) - 15g (5 min)<br />
                <br />
                Gær: Saflager W34/70.<br />
                (Skulle have brug en WLP300 - Hefeweizen Yeast - White Labs - men den var et halvt år for gammel og vi troede ikke at den kunne bruges, men gærstarteren var godt i gang om mandagen - så vi bruger den senere - den står fortsat på køl :-))<br />
                <br />
                OG: 1056<br />
                MG: 1028 (14/2)<br />
                FG: 1015 (20/2)<br />
                Effektivitet: 63%<br />
                Alkoholprocent: 5.4<br />
                EBC: 21<br />
                IBU: 20<br />
                <br />
                Første gang der brygges med ny mæskespand - processen var meget langsom. Der er noget at optimere på, idet effektiviteten ikke blev forbedret i forhold til tidligere bryg.<br />
                Den ny pladekøler blev taget i brug. Den køler godt/hurtigt.<br />
                Flot gylden hvedeøl med lækkert skum. Mild bitterhed.<br />
                <br />
                <img src={ImgBryggraf} width="670" height="300" alt="Bryg7graf" />
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container >
  );
}

