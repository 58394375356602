import ImgBryggraf from '../../assets/bryg/brew4.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>4. Bryg - Solholt Jule Ale</h1>

          <table>
            <tr>
              <td>
                Brygdag lørdag d. 23. september 2006.<br />
                <i>(ingredienser købt hos Haandbryggeriet - som desværre er lukket)</i><br />
                <br />
                Malt:<br />
                Pale Ale Malt, Malterie du Château, Belgien (8 EBC) - 5,8kg<br />
                Cara Munich, Malterie du Château, Belgien (115 EBC) - 1,2kg<br />
                Hvede malt, Malterie du Château, Belgien (4 EBC) - 0,3kg<br />
                <br />
                Humle:<br />
                East Kent Golding ( a 5,6%) - 50g (60 min)<br />
                East Kent Golding ( a 5,6%) - 40g (15 min)<br />
                Williamette (a 6,6%) - 30g (5 min)<br />
                <br />
                Gær: Safale S-04<br />
                1. gæring i 7 dage ved ca. 20°<br />
                2. gæring i 7 dage ved ca. 20°<br />
                Lagring i 3 uger ca. 6° - før omstikning til fustage sænkes temperaturen til 2° i 2 dage.<br />
                <br />
                OG: 1050<br />
                FG: 1018<br />
                Alkohol: ca. 4,2%<br />
                Farve: 28 EBC<br />
                Bitterhed: 32 IBU<br />
                <br />
                Bemærkning:<br />
                Vi tilsatte Carrageenan for at få brygget mere klart.<br />
                <br />
                <img src={ImgBryggraf} width="670" height="300" alt="Bryg4graf" /><br />
                Temperaturgrafen - (bemærk: førsteaksen er ikke lineær).<br /><br /><br />


              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container >
  );
}

