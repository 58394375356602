import ImgBryggraf from '../../assets/bryg/brew6.jpg';
import ImgBryg from '../../assets/bryg/bryg6.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>6. Bryg - Solholt Hvede</h1>
          <table>
            <tr>
              <td>
                Brygdag lørdag d. 2. juni 2007.<br />
                <i>(ingredienser købt hos Haandbryggeriet - som desværre er lukket)</i><br /><br />
                <br />
                Malt:<br />
                <br />
                Hvede malt, Malterie du Château, Belgien (3 EBC) - 3,5kg<br />
                Pale Ale malt, Malterie du Château, Belgien (7 EBC) - 2,5kg<br />
                Cara 50, Malterie du Château, Belgien (50 EBC) - 0,5kg<br />
                Cara hvede, Weyermann, Tyskland (120 EBC) - 0,5kg<br />
                <br />
                Humle:<br />
                Hersbrucker ( a 3,8%) - 45g (60 min)<br />
                Hersbrucker ( a 3,8%) - 10g (10 min)<br />
                Hersbrucker ( a 3,8%) - 10g (5 min)<br />
                <br />
                Gær: Safale K-97<br />
                <br />
                OG: 1047<br />
                FG: 1015<br />
                <br />
                Brygget er færdiggæret og nedkølet til ca. 2 grader. Omstukket til fustage d. 1/7.<br />
                Tvangskarboneret d. 1/7 om eftermiddagen og testsmagt om aftenen.<br />
                Meget fin afbalanceret smag. Mild humle aroma og bitterhed - meget passende.<br />
                Flot "klar" hvede - uden brug af klaringsmiddel.<br />
                God krop og flot skum.<br />
                <br />
              </td>
              <td>
                <img src={ImgBryg} width="350" height="409" alt="Bryg6" />
              </td>
            </tr>
            <tr>
              <td colspan="2"><img src={ImgBryggraf} width="670" height="300" alt="Bryg6graf" />
                <br /><br /><br />
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container >
  );
}

