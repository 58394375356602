import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Vin/øl hybrid</h1>

          <table>
            <tr>
              <td>
                Efter en god vindrue høst i 2007 lavede vi et lille eksperiment.<br />
                <br />
                Saften blev kogt af druerne med Christians forældres tutti-frutti. :-)<br />
                For at gøre urten mere modstandsdygtig over for bakterier, så blev der tilsat 30g Hersbrücker (som tørhumle).<br />
                Der blev lavet en gærstarter med en rest ølgær vi havde fra et tidligere bryg (en safale, type ukendt).<br />
                <br />
                Oechslevægten viste 1038 da urten var kølet ned til 20 grader. Der blev tilsat sukker - OG måles til 1070.<br />
                Der blev gæret ned til 1015 - der blev tilsat sukker - vægtfylden blev målt til 1020.<br />
                Der blev gæret ned til 1011 - der blev tilsat sukker så vægtfylden kom op på 1049.<br />
                Der blev til sidst gæret ned til 1024. Brygget blev kølet ned til 2 grader, lagrede i en måneds tid på vinbalonen og blev derefter stukket om på fustage og der blev tilsat CO2.<br />
                <br />
                Alkoholprocent beregnes som:<br />
                <br />
                1070-1015 + 1020-1011 + 1049-1024 = 55 + 9 + 25 = 89.<br />
                89 / 8 = ca. 11%<br />
                <br />
                Drikken som vi omtaler som "vøl" er afprøvet som velkomstdrink. En OK vøl. :-)<br /><br /><br />
              </td>
            </tr>
          </table>
          <br />
          <br />
          <br />
        </Col>
      </Row>
    </Container >
  );
}

