import ImgHumle from '../../assets/bryg/humle.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Brygning</h1>
          <p>
            For et par hundrede år siden var ølbrygning en naturlig del
            af husholdningen på gårdene. Ølbrygning havde vi som en hobby på
            Solholt, men har ikke brygget siden 2012.<br/>
          </p>
          <p><a href="/brygning/generelt">Lidt generelt om brygning</a></p>
          <p><a href="/Brygning/brygningsolholt">Sådan bryggede vi på Solholt (2005-2012)</a></p>
          <p>
            Første 2 bryg blev købt som all grain kit<br></br>
            <a href="/Brygning/1brygengelskale">1. Bryg - Engelsk Ale - ESB (2005)</a><br />
            <a href="/Brygning/2brygengelskale">2. Bryg - Engelsk Ale - ESB (2006)</a><br />
            <br/>
            <a href="/Brygning/3brygtjekkiskpilsner">3. Bryg - Solholt Tjekkisk Pilsner (2006)</a><br />
            <a href="/Brygning/4brygengelskale">4. Bryg - Solholt Jule Ale (2006)</a><br />
            <a href="/Brygning/5brygsolholtpilsner">5. Bryg - Solholt Pilsner (2006)</a><br />
            <br/>
            <a href="/Brygning/6brygsolholthvede">6. Bryg - Solholt Hvede (2007)</a><br />
            <a href="/Brygning/vinoelhybrid">Eksperiment 1 - Vin/øl hybrid (2007)</a><br />
            <br />
            <a href="/Brygning/7brygsolholthvede2">7. Bryg - Solholt Hvede 2.0 (2008)</a><br />
            <a href="/Brygning/8brygschwarzbier">8. Bryg - Schwarzbier (2008)</a><br />
            <br />
            <a href="/Brygning/9brygsolholtclassic">9. Bryg - Solholt Classic (2009)</a><br />
            <br />
            10. Bryg - Solholt Pilsner<br />
            11. Bryg - Solholt Dubbel (Belgisk Dubbel)
          </p>
        </Col>
        <Col> 
          <img src={ImgHumle} width="400" height="533" alt="Humle" />
        </Col>
      </Row>
    </Container >
  );
}

