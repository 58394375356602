import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>1. Bryg - Engelsk Ale (English Strong Bitter)</h1>
          <table>
            <tr>
              <td>
                Brygget midt i september 2005. (vores noter er gået tabt, så loggen er forsøgt genskabt udfra hukommelsen og gamle mails).<br />
                <i>(Abbey Road fra Torben Lund - købt samlet hos Haandbryggeriet - som desværre er lukket)</i><br /><br />

                Mæskeprogram:<br />
                58° / 30 min., 63°/ 30, 68°/ 30, 76°/ 20 inkl. tid hvor maskespand hæves<br /><br />

                Gær: Safale S-04<br />
                1. gæring i 8 dage ved ca. 20°<br />
                2. gæring i 7 dage ved ca. 20°<br /><br />

                Alkohol: ca. 5%<br /><br />

                I alt blev det til ca. 17 liter øl.<br />
                Eftergærede på flaske med spraymalt.<br />
                Smagte helt fint. Vi erfarede dog at flaskerne skal stå ved stue temperatur og eftergære i er par uger, efterfulgt af et par ugers lagring på køl, for at smagen var helt ok.
              </td>
            </tr>
          </table>
          <br/>
          <br/>
          <br/>
        </Col>
      </Row>
    </Container >
  );
}

