import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>2. Bryg - Engelsk Ale (English Strong Bitter)</h1>

          <table>
            <tr>
              <td>
                Brygdag søndag d. 2. april 2006.<br />
                <i>(brygget efter Torben Lunds "Kokhus Åben Bryg" - ingredienser købt hos Haandbryggeriet som desværre er lukket)</i><br /><br />

                Malt:<br />
                Pale Ale Malt (6 EBC) - 5kg<br />
                Cara Munich (110 EBC) - 1kg<br />
                <br />
                Humle:<br />
                Pilgrim (a 11,8%) - 25g (60 min)<br />
                East Kent Golding ( a 5,6%) - 30g (15 min)<br />
                East Kent Golding ( a 5,6%) - 20g (5 min)<br />
                <br />
                Mæskeprogram:<br />
                58° / 30 min., 63°/ 30, 68°/ 30, 76°/ 20 inkl. tid hvor maskespand hæves<br />
                <br />
                Gær: Safale S-04<br />
                1. gæring i 7 dage ved ca. 20°<br />
                2. gæring i 13 dage ved ca. 20°<br />
                <br />
                OG: 1058<br />
                FG: 1015<br />
                Alkohol: ca. 5,6%<br />
                Farve: 26 EBC<br />
                Bitterhed: 34 IBU<br />
                <br />
                Beskrivelse:<br />
                Mæskning forløb planmæssigt.<br />
                Mæskning i mæskespanden fungerede godt og løb fint igennem uden at klumpe sammen.<br />
                Der blev udmæsket med ca. 10 liter ca. 80° vand efter spanden var hævet helt op over urt-overfladen efter 20 minutter ved 76°.<br />
                Der blev udtaget ca. 1,5 dl urt som blev nedkølet og gæren tilsat, i stedet for at lave en gærstarter.<br />
                Humlen blev kogt planmæssigt og urten blev nedkølet efter endt kogning.<br />
                Chilleren kogte med de sidste 10 minutter. Nedkøling skete under omrøring på ca. 10 minutter.<br />
                Urten blev stukket om fra gruekedlen til vinbalonen og gæropløsningen blev tilsat.<br />
                Vinbalonen blev pakket ind i nogle tæpper. Gæren begyndte langsomt at komme i gang. Efter 4-5 timer kom der et kluk en gang imellem.<br />
                Næste morgen var der mere gang i det og om eftermiddagen efter ca. 24 timers gæring var der rigtig godt gang i det. Temperaturen på vinbalonen var ca. 22°, og temperaturen i rummet var ca. 18-19°.<br />
                Gærede meget den næste dag.<br />
                Tirsdag d. 4. april - eftermiddag var der kun lidt gang i det, temperaturen var omkring 19° på balonen.<br />
                Tirsdag aften blev balonen sat i køleskabet for at forsøge at skabe en jævn nattetemperatur.<br />
                Onsdag d. 5. april om morgenen var temperaturen 17-18° og ikke synlig gang i gæringen. Om eftermiddagen var temperaturen ca. 17°. Vi forsøger at sætte en elpære i køleskabet for at forsøge at hæve temperaturen.<br />
                Torsdag d. 6. april. Temperaturen holder ca. 17-18°. Der kommer et lille kluk ca. hver 20. sek.<br />
                Søndag d. 9. april stukket om på sekundær. Vægtfylde var på 1016.<br />
                Lørdag d. 22. april stukket om på festfade. Vægtfylde var på 1015.<br />
                <br />
                I alt blev det til ca. 18,5-19 liter øl. Smager allerede fint. Serveret for familien d. 23. april.<br />
                Fredag d. 5. maj serveret på arbejdet - det var en succes.<br /><br /><br />
              </td>
            </tr>
          </table>
          <br />
          <br />
          <br />
        </Col>
      </Row>
    </Container >
  );
}

