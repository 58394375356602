import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Page from './Page';
import Home from './Home';
import Allan from './Allan';
import Wood from './Wood';
import Brygning from './brygning/Brygning';
import Generelt from './brygning/Generelt';
import Brygningsolholt from './brygning/Brygningsolholt';
import Bryg1Ale from './brygning/1-bryg-ale';
import Bryg2Ale from './brygning/2-bryg-ale';
import Bryg3Tjekpils from './brygning/3-bryg-tjekkiskpils';
import Bryg4juleale from './brygning/4-bryg-juleale';
import Bryg5pils from './brygning/5-bryg-pils';
import Bryg6hvede from './brygning/6-bryg-hvede';
import Voel from './brygning/voel';
import Bryg7hvede from './brygning/7-bryg-hvede2';
import Bryg8 from './brygning/8-bryg-schwarz';
import Bryg9 from './brygning/9brygclassic';

import Christian from './Christian';
import Photos from './Photos';
import Log from './Log';
import Shopping from './Shopping';
import NoPage from './404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Page />}>
      <Route path="/" element={<Home />} />
      <Route path="allan" element={<Allan />} />
      <Route path="allan/wood" element={<Wood />} />
      <Route path="brygning" element={<Brygning />} />
      <Route path="brygning/generelt" element={<Generelt />} />
      <Route path="brygning/brygningsolholt" element={<Brygningsolholt />} />
      <Route path="brygning/1brygengelskale" element={<Bryg1Ale />} />
      <Route path="brygning/2brygengelskale" element={<Bryg2Ale />} />
      <Route path="brygning/3brygtjekkiskpilsner" element={<Bryg3Tjekpils />} />
      <Route path="brygning/4brygengelskale" element={<Bryg4juleale />} />
      <Route path="brygning/5brygsolholtpilsner" element={<Bryg5pils />} />
      <Route path="brygning/6brygsolholthvede" element={<Bryg6hvede />} />
      <Route path="brygning/vinoelhybrid" element={<Voel />} />
      <Route path="brygning/7brygsolholthvede2" element={<Bryg7hvede />} />
      <Route path="brygning/8brygschwarzbier" element={<Bryg8 />} />
      <Route path="brygning/9brygsolholtclassic" element={<Bryg9 />} />
      
      
      
      <Route path="christian" element={<Christian />} />
      <Route path="fotoalbum" element={<Photos />} />
      <Route path="log" element={<Log />} />
      <Route path="shopping" element={<Shopping />} />
      <Route path="*" element={<NoPage />} />
    </Route>
  )
);

function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router;
