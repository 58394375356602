import ImgBryggraf from '../../assets/bryg/brew5.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>5. Bryg - Solholt Pilsner</h1>

          <table>
            <tr>
              <td>
                Brygdag lørdag d. 30. december 2006.<br />
                <i>(ingredienser købt hos Haandbryggeriet - som desværre er lukket)</i><br /><br />
                <br />
                Malt:<br />
                Pilsner malt, Malterie du Château, Belgien (3 EBC) - 5kg<br />
                Pale Ale malt, Malterie du Château, Belgien (7 EBC) - 0,7kg<br />
                Hvede malt, Malterie du Château, Belgien (4 EBC) - 0,3kg<br />
                Cara 20, Malterie du Château, Belgien (16 EBC) - 1kg<br />
                Melanoidin, Malterie du Château, Belgien (38 EBC) - 0,5kg<br />
                <br />
                Humle:<br />
                Pilgrim ( a 11,8%) - 20g (60 min)<br />
                Egen humle ( ukendt alpha) - 30g (15 min)<br />
                Egen humle ( ukendt alpha) - 40g (5 min)<br />
                <br />
                Gær: Safale S-23<br />
                1. gæring i 3 uger ved ca. 14-15°<br />
                Lagring i 1 uge før omstikning til fustage ved ca. 6° - før omstikning til fustage sænkes temperaturen til 2°.<br />
                Herefter lagring ved ca. 5°.<br />
                Dette bryg var første forsøg uden omstikning til sekundær gæring. Det blev afprøvet da der kun var ganske lidt bundfald i gærtanken. Øllet var ok klart, men fremover omstikker vi til sekundær gæring. :-)
                <br /><br />
                OG: 1049<br />
                FG: 1013<br />
                Alkohol: ca. 4,7%<br />
                Farve: 12 EBC<br />
                Bitterhed: 20-30 IBU er vores bud<br />
                <br />
                Bemærkning:<br />
                Vi tilsatte Carrageenan for at få brygget mere klart.<br />
                <img src={ImgBryggraf} width="670" height="300" alt="Bryg5graf" /><br />
                Temperaturgrafen - (bemærk: førsteaksen er ikke lineær).<br /><br /><br />
              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container >
  );
}

