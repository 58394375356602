import ImgBryg6 from '../../assets/bryg/bryg6_173x202.jpeg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Generelt om brygning</h1>
          <p>
            <Col>
              <img src={ImgBryg6} width="173" height="202" alt="Bryg6" className='navbar-toggler' />
            </Col>
            Det er ikke sværere at brygge øl end det er at bage, og det kræver heller ikke den store inventarliste. Der er forskellige måder at gribe brygningen an på, og utalligt udstyr man kan benytte. Her skitserer vi hovedprincipperne i brygning.
          </p>
          <p>For at brygge kan man principielt nøjes med:</p>
          <ul>
            <li>en stor gryde til at ”koge” malt og humle i</li>
            <li>et dørslag til at si malten fra med</li>
            <li>en vinballon til at gære i, samt en gærlås</li>
            <li>en hævert eller en plastikslange til at komme øllet på flasker med</li>
          </ul>
          <p>Dertil kommer noget rengøringsmiddel og noget til at desinficere med (f.eks. brintoverilte).</p>
          <p>Malt, humle og gær skal man have anskaffet i de passende mængder; opskrifter findes i massevis på nettet, f.eks. på <a href="http://www.haandbryg.dk/">www.haandbryg.dk</a> og råvarerne kan også købes på nettet via links fra denne hjemmeside.</p>
          <p>Når man har fundet opskriften på det øl man kunne tænke sig at brygge, og evt. lavet lidt om på den, og har fået købt alle råvarerne, så kan man gå og glæde sig til brygdagen. Det vil være en god idé at afsætte en hel dag til projektet, da det tager noget tid, og man jo nødig skulle blive stresset. Det som især tager lang tid er opvarmning af vand, kogning, afkøling samt rengøring, rengøring og rengøring. De sidste tre ting er vigtige. :-)</p>
          <p>På brygdagen skal man igennem følgende:</p>
          <ul>
            <li>”kogning” af malt (mæskning)</li>
            <li>sining af malt (udmæskning) </li>
            <li>kogning med humle </li>
            <li>afkøling</li>
            <li>tilsætning af gær</li>
          </ul>
          Man starter med at opvarme den mængde vand man skal bruge, og tilsætter maltblandingen. I denne proces, som kaldes for mæskning, ”koger” man malten og trækker derved sukkerstoffer ud af malten – koger i anførselstegn, da temperaturen ikke er på kogepunktet, men gradvis hæves op til ca. 78 grader. Processen tager ca. 1 - 1½ time. Herefter skal man have siet malten fra, denne proces kaldes for ”udmæskning”.
          <br /><br />
          Når malten er siet fra, har man en væske der kaldes en ”urt”. Urten er en meget sød substans, nu sukkerstofferne er trukket ud af malten. Urten bringes i kog og så tilsættes humlen. Humlen koger med i en times tid. Der tilsættes mere humle i slutning af kogningen. Dette gøres for at få forskellige smagsstoffer ud af humlen. Den humle, der koger med i længst tid, bidrager mest til den bitre smag. Den humle, der koges med i kort tid, f.eks. 5-10 minutter, bidrager med humlens blomstersmag, men når ikke at give meget bitter smag fra sig.
          <br /><br />
          Herefter skal man være omhyggelig med rengøring og desinficering, da urten nu skal køles ned. Frem til denne del af processen har den høje temperatur slået eventuelle bakterier ihjel, men herefter vil der være risiko for at urten bliver inficeret – derfor skal man sørge for at alt, som urten kommer i kontakt med, er desinficeret. Urten køles ned til 20-25 grader og hældes over på den rengjorte og desinficerede vinballon. Herefter tilsættes gæren, og ballonen sættes et mørkt sted, hvor den nu skal stå og passe sig selv.
          <br /><br />
          Efter et halvt til et helt døgn er gæren godt i gang. Det ses på det korte interval mellem blop’ene i gærlåsen. Gæren er i gang med at spise sukkerstofferne – som den rigtig godt kan lide – og restproduktet bliver alkohol og kuldioxid. Afhængig af gærtypen, gæres der ved høj (ca. 17-25 grader) eller lav (ca. 7-15 grader) temperatur. Gæren bidrager også med smagsstoffer, nogle gærtyper mere end andre.
          <br /><br />
          Efter 2-6 uger, afhængig af øltypen, er gæringen ved at være færdig. Det ses igen på intervallerne mellem blop’ene. Når der er mere end 5 minutter mellem hver blop, regner man gæringen for at være færdig.
          <br /><br />
          Øllet er nu klar til at komme på flaske eller fad. Et fadølsanlæg med fustager og CO2 på trykflaske er det letteste at bruge, men kræver lidt investering. Mange begynder derfor med at bruge almindelige flasker. For at få tryk og ”brus” i øl på flasker, bruger man som hjemmebrygger en teknik der hedder efterkarbonering. Det betyder, at man tilsætter en lille smule sukker i hver flaske, hvorved gæren kommer i gang igen, og danner CO2 i flasken. Ud over den ønskede CO2 giver denne metode dog også lidt bundfald i ølflasken. Ved efterkarbonering skal flaskerne stå ved stuetemperatur i ca. 14 dage mens gæren arbejder. Herefter sættes flaskerne på køl, eller et køligt sted, i mindst 14 dage. Det ville være mærkeligt, hvis man ikke havde smagt på øllet inden da, men øllet er bedst efter det har lagret et stykke tid, specielt når øllet er blevet efterkarboneret. :-)
      </Col>
    </Row>
    </Container >
  );
}

