import ImgBryggraf from '../../assets/bryg/brew8.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>8. Bryg - Solholt Schwarzbier (påskeøl)</h1>

          <table>
            <tr>
              <td>
                Brygget påskesøndag og mandag d. 23-24/3-2008.<br />
                <i>(ingredienser købt hos Haandbryggeriet - som desværre er lukket)</i><br /><br />
                <br />
                Malt:<br />
                Pilsner malt, Malterie du Château, Belgien (7 EBC) - 2,5kg (41%)<br />
                Hvede malt, Malterie du Château, Belgien (3 EBC) - 0,5kg (8%)<br />
                CaraMunich 120, Malterie du Château, Belgien (120 EBC) - 0,5kg (8%)<br />
                CaraMunich 25, Malterie du Château, Belgien (25 EBC) - 2,5kg (41%)<br />
                Chokolade malt (900 EBC) - 80g (1%)<br />
                Black malt (1500 EBC) - 20g (0%)<br />
                <br />
                Humle:<br />
                Hallertau Aroma ( a 7,2%) - 20g (60 min)<br />
                Hallertau Aroma ( a 7,2%) - 10g (10 min)<br />
                Hallertau Aroma ( a 7,2%) - 10g (5 min)<br />
                <br />
                Gær: 2. generation af gær fra bryg 7.<br />
                <br />
                OG: 1052<br />
                FG: 1016<br />
                Effektivitet: 78%<br />
                Alkoholprocent: 4,7<br />
                EBC: 35<br />
                IBU: 16<br />
                <br />
                Der blev denne gang brugt isolering på mæskespanden, samt en meget langsom udmæskning, som resulterede i den bedste effektivitet til dato.<br />
                Frataget ca. 1 liter til gærstartere - 2*3dl er frosset.<br />
                Carrageenan tilsat de sidste 10 minutter - 0,5 gr. pr. ltr. urt (14gr. tilsat).<br />
                Flot mørk øl med markant karamelsmag.<br />
                <br />
                <img src={ImgBryggraf} width="670" height="300" alt="Bryg8graf" />
                <br /><br /><br />

              </td>
            </tr>
          </table>
        </Col>
      </Row>
    </Container >
  );
}

